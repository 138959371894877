import { useContext } from 'react';
import IAuthContext from 'Core/types/IAuthContext';
import { AuthContext } from 'Core/context/AuthProvider';

const useAuth = function (): IAuthContext {
  const context = useContext(AuthContext);

  return context;
};

export default useAuth;
