import React from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet
} from 'react-router-dom';
import routes from 'Core/config/routes';
import useAuth from 'Core/hooks/useAuth';

const PrivateRoute: React.FC = function () {
  const { isAuthenticated } = useAuth();
  return (
    isAuthenticated()
      ? (
        <Outlet />
      )
      : <Navigate to="/login" />
  );
};

const App: React.FC = function () {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route) => (
          route.private
            ? (
              <Route path={route.path} element={<PrivateRoute />}>
                <Route path={route.path} element={route.element} />
              </Route>
            )
            : (
              <Route
                path={route.path}
                element={route.element}
                key={route.path}
              />
            )
        ))}
      </Routes>
    </BrowserRouter>

  );
};
export default App;
