const Button = {
  baseStyle: {
    borderRadius: 0,
    textTransform: 'uppercase',
    fontFamily: 'DM Mono',
    fontWeight: 500
  }
};

export default Button;
