import React from 'react';
import {
  Container, Heading, Image, Grid, Box, Link, Flex, Text
} from '@chakra-ui/react';
import Pf from 'Assets/pf1.png';
import Pj from 'Assets/pj1.png';

const PfPi: React.FC = function () {
  return (
    <Box id="saber-mais" bgColor="brand.100">
      <Container maxW="container.xl" pt="140px" pb="80px">
        <Grid templateColumns={{ base: '1fr', lg: '1fr 1fr' }} gap="24px" alignItems="center">
          <Flex direction={{ base: 'column-reverse', lg: 'column' }} bgColor="white" p={{ base: '32px', lg: '56px' }}>
            <Box>
              <Heading
                as="h2"
                mb="32px"
                color="brand.900"
                fontWeight="500"
                fontSize={{ base: '24px', lg: '28px' }}
                lineHeight="1.4em"
              >
                Análise de crédito com ampla visão de dados para
                aprovação de
                {' '}
                <Text d="inline" color="brand.500">pessoa jurídica</Text>
                .
              </Heading>
              <Link href="#analise" color="brand.500" textTransform="uppercase">Fazer análise</Link>
            </Box>
            <Box
              height={{ base: '300px', lg: '350px' }}
              mb={{ base: '62px', lg: 0 }}
            >
              <Image
                src={Pj}
                height={{ base: 'auto', lg: '120%' }}
                mx="auto"
                transform={{ base: '0', lg: 'translateY(60px)' }}
              />
            </Box>
          </Flex>

          <Box bgColor="white" p={{ base: '32px', lg: '56px' }}>
            <Box
              height={{ base: '300px', lg: '350px' }}
              mb={{ base: '32px', lg: 0 }}
            >
              <Image
                src={Pf}
                height={{ base: 'auto', lg: '115%' }}
                mx="auto"
                transform={{ base: '0', lg: 'translateY(-130px)' }}
                width={{ base: '90%', lg: 'auto' }}
              />
            </Box>
            <Box>
              <Heading
                as="h2"
                mb="32px"
                color="brand.900"
                fontWeight="500"
                fontSize={{ base: '24px', lg: '28px' }}
                lineHeight="1.4em"
              >
                Análise de dados comportamentais para
                aprovação
                {' '}
                <Text d="inline" color="brand.500">pessoa física</Text>
                {' '}
                desbancarizada.
              </Heading>
              <Link href="#analise" color="brand.500" textTransform="uppercase">Fazer análise</Link>
            </Box>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default PfPi;
