import React from 'react';

import {
  Box,
  Text
} from '@chakra-ui/react';
import GaugeChart from 'react-gauge-chart';
import Card from 'Components/elements/Card';
import { Radar, Column } from '@ant-design/plots';

interface IScore {
  shap: any
}

const Shap: React.FC<IScore> = function ({ shap }) {
  let data: any = [];

  try {
    if (shap) {
      for (let i = 0; i < shap.featureNames.length; i += 1) {
        try {
          const effect = (shap.features[i].effect).toFixed(2);
          console.log(effect);
          if (effect !== '0.00' && effect !== '-0.00') {
            data.push({
              name: shap.featureNames[i],
              value: parseFloat((shap.features[i].effect * 100).toFixed(2))
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
      data = data.sort((a: any, b: any) => a.value - b.value);
      console.log(data);
    }
  } catch (error) {
    console.log(error);
  }

  // const config = {
  //   data,
  //   xField: 'name',
  //   yField: 'value',
  //   appendPadding: [0, 10, 0, 10],
  //   xAxis: {
  //     tickLine: null
  //   },
  //   meta: {
  //     star: {
  //       formatter: (v: any) => Number(v).toFixed(2)
  //     }
  //   },
  //   yAxis: {
  //     label: false,
  //     grid: {
  //       alternateColor: 'rgba(0, 0, 0, 0.04)'
  //     }
  //   },
  //   // 开启辅助点
  //   point: {
  //     size: 2
  //   },
  //   area: {}
  // };
  const config = {
    data,
    xField: 'name',
    yField: 'value',
    columnWidthRatio: 0.8,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false
      }
    }
  };
  return (
    <Card title="Fatores de Decisão (%)" testId="score">
      <Box width="100%" position="relative" mx="auto">
        <Column {...config} />
      </Box>
    </Card>
  );
};
export default Shap;
