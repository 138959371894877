import React from 'react';
import { Box } from '@chakra-ui/react';
import ScoreiHeader from 'Components/layout/ScoreiHeader';
import ScoreiGauge from 'Components/pages/ScoreiGauge';
import Footer from 'Components/layout/Footer';
import Contact from 'Components/pages/Contact';

const Scorei: React.FC = function () {
  return (
    <Box>
      <ScoreiHeader />
      <ScoreiGauge />
      <Contact />
      <Footer />
    </Box>
  );
};

export default Scorei;
