interface IUser {
  name: string,
  email: string,
  phone?: string,
  birthday?: string,
  cep?: string,
  cpf?: string,
  state?: string,
  city?: string,
  neighborhood?: string,
  street?: string,
  number?: string,
  complement?: string,
}

export const DEFAULT_VALUES = {
  name: '',
  email: '',
  phone: undefined,
  birthday: undefined,
  cep: undefined,
  cpf: undefined,
  state: undefined,
  city: undefined,
  neighborhood: undefined,
  street: undefined,
  number: undefined,
  complement: undefined
};

export default IUser;
