import React from 'react';
import {
  Text, Container, Flex, Link, Box, HStack
} from '@chakra-ui/react';
import { GrLinkedin, GrFacebook } from 'react-icons/gr';

const Footer: React.FC = function () {
  return (
    <Container maxW="container.xl" position="relative">
      <Flex
        borderTop="1px solid"
        borderTopColor="borderGray"
        py="32px"
        color="subGray"
        justifyContent="space-between"
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <Box
          mb={{ base: '16px', lg: 0 }}
          textAlign={{ base: 'center', lg: 'left' }}
        >
          <Link
            href="https://www.dataholics.io/termos-e-condicoes/"
            mr={{ base: 0, lg: '24px' }}
            display={{ base: 'block', lg: 'inline-block' }}
            mb={{ base: '16px', lg: 0 }}
          >
            TERMOS E CONDIÇÔES DE USO
          </Link>

        </Box>

        <HStack spacing="16px" mx={{ base: 'auto', lg: '0' }}>
          <Link href="https://www.linkedin.com/company/socialise/">
            <GrLinkedin size="20px" />
          </Link>
          <Text
            fontFamily="DM Mono"
            textAlign={{ base: 'center', lg: 'left' }}
          >
            © 2022 Dataholics
          </Text>
        </HStack>
      </Flex>
    </Container>
  );
};

export default Footer;
