import React from 'react';
import {
  Grid, Box,
  Heading
} from '@chakra-ui/react';
import IOffer from 'Core/types/IOffer';
import Offer from 'Components/elements/Offer';

interface Props {
  offers: IOffer[]
}
const CreditOffers: React.FC<Props> = function ({ offers }) {
  return (
    <Box>
      <Heading
        as="h2"
        fontSize="18px"
        fontWeight="600"
        mb="24px"
        color="gray.600"
      >
        Ofertas de crédito
      </Heading>

      <Grid templateColumns={{ base: '1fr', lg: '1fr' }} gap="16px">
        {
          offers.map((offer) => (
            <Offer
              name={offer.name}
              value={offer.limit}
              plots={offer.minimum_plots}
              valuePlots={offer.value_plots}
            />
          ))
        }
      </Grid>
    </Box>
  );
};

export default CreditOffers;
