import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.dataholics.io/api/'
  // baseURL: 'http://localhost:3003/api/'
});

api.defaults.headers.common = { Authorization: `Bearer ${localStorage.getItem('token')}` };

export default api;
