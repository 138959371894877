import React, { useState } from 'react';

import Card from 'Components/elements/Card';
import {
  Box, Stack, FormControl, FormLabel, Input, Button, useToast
} from '@chakra-ui/react';
import IPersonalDataForm from 'Core/types/IPersonalDataForm';
import api from 'Core/services/api';
import useAuth from 'Core/hooks/useAuth';

const PersonalDataForm: React.FC<IPersonalDataForm> = function ({ user }) {
  const [name, setName] = useState<string>(user.name);
  const [birthday, setBirthday] = useState<string | undefined>(user.birthday);
  const [cep, setCep] = useState<string | undefined>(user.cep);
  const [cpf, setCpf] = useState<string | undefined>(user.cpf);
  const [state, setState] = useState<string | undefined>(user.state);
  const [city, setCity] = useState<string | undefined>(user.city);
  const [neighborhood, setNeighborhood] = useState<string | undefined>(user.neighborhood);
  const [street, setStreet] = useState<string | undefined>(user.street);
  const [number, setNumber] = useState<string | undefined>(user.number);
  const [complement, setComplement] = useState<string | undefined>(user.complement);

  const [submiting, setSubmiting] = useState(false);

  const toast = useToast();

  const { updateUser } = useAuth();

  const submit = async function (): Promise<void> {
    try {
      if (!name) {
        toast({
          title: 'Informe seu nome.',
          description: 'Informe seu nome para completar a atualização.',
          status: 'warning',
          duration: 9000,
          isClosable: true
        });

        return;
      }

      setSubmiting(true);
      const { data } = await api.patch('user', {
        name,
        cpf,
        cep,
        birthday,
        state,
        city,
        street,
        neighborhood,
        number,
        complement
      });

      updateUser(data.user);

      setSubmiting(false);
    } catch (e) {
      toast({
        title: 'Ops',
        description: 'Algo de errado aconteceu.',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
      setSubmiting(false);
    }
  };

  return (
    <Card title="Dados pessoais" testId="personal-data-form">
      <Box>
        <Stack spacing="24px">
          <FormControl id="nome">
            <FormLabel mb="8px">Seu nome</FormLabel>
            <Input
              type="text"
              placeholder="Seu nome..."
              variant="custom"
              size="lg"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl id="nascimento">
            <FormLabel mb="8px">Data de nascimento</FormLabel>
            <Input
              type="date"
              variant="custom"
              size="lg"
              value={birthday}
              onChange={(e) => setBirthday(e.target.value)}
            />
          </FormControl>
          <FormControl id="cpf">
            <FormLabel mb="8px">CPF</FormLabel>
            <Input
              type="text"
              placeholder="Seu CPF..."
              variant="custom"
              size="lg"
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
            />
          </FormControl>
          <FormControl id="cep">
            <FormLabel mb="8px">CEP</FormLabel>
            <Input
              type="text"
              placeholder="Seu CEP..."
              variant="custom"
              size="lg"
              value={cep}
              onChange={(e) => setCep(e.target.value)}
            />
          </FormControl>
          <FormControl id="nome">
            <FormLabel mb="8px">Estado</FormLabel>
            <Input
              type="text"
              placeholder="Seu nome..."
              variant="custom"
              size="lg"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
          </FormControl>
          <FormControl id="telefone">
            <FormLabel mb="8px">Cidade</FormLabel>
            <Input
              type="text"
              placeholder="Seu telefone..."
              variant="custom"
              size="lg"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </FormControl>
          <FormControl id="bairro">
            <FormLabel mb="8px">Bairro</FormLabel>
            <Input
              type="text"
              placeholder="Seu bairro..."
              variant="custom"
              size="lg"
              value={neighborhood}
              onChange={(e) => setNeighborhood(e.target.value)}
            />
          </FormControl>
          <FormControl id="rua">
            <FormLabel mb="8px">Rua</FormLabel>
            <Input
              type="text"
              placeholder="Sua rua..."
              variant="custom"
              size="lg"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
            />
          </FormControl>
          <FormControl id="numero">
            <FormLabel mb="8px">Número</FormLabel>
            <Input
              type="text"
              placeholder="Seu número..."
              variant="custom"
              size="lg"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
          </FormControl>
          <FormControl id="complemento">
            <FormLabel mb="8px">Complemento</FormLabel>
            <Input
              type="text"
              placeholder="Seu complemento..."
              variant="custom"
              size="lg"
              value={complement}
              onChange={(e) => setComplement(e.target.value)}
            />
          </FormControl>
        </Stack>

        <Button
          colorScheme="brand"
          mt="24px"
          onClick={() => submit()}
          isLoading={submiting}
        >
          Salvar alterações
        </Button>
      </Box>
    </Card>
  );
};

export default PersonalDataForm;
