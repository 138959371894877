import React from 'react';

import { Stack, Text } from '@chakra-ui/react';
import { Link as ReactLink, useLocation } from 'react-router-dom';

const SettingsSidebar: React.FC = function () {
  const { pathname } = useLocation();

  return (
    <Stack spacing="24px">
      <ReactLink to="/configuracoes">
        <Text
          color={pathname === '/configuracoes' ? 'brand.500' : 'black'}
          fontWeight={500}
          fontFamily="DM Mono"
        >
          Dados pessoais
        </Text>
      </ReactLink>
      <ReactLink to="/configuracoes/pagamento">
        <Text
          color={pathname === '/configuracoes/pagamento' ? 'brand.500' : 'black'}
          fontWeight={500}
          fontFamily="DM Mono"
        >
          Pagamento
        </Text>
      </ReactLink>
      {/* <ReactLink to="/configuracoes/recibos">
        <Text
          color={pathname === '/configuracoes/recibos' ? 'brand.500' : 'black'}
          fontWeight={500}
          fontFamily="DM Mono"
        >
          Recibos
        </Text>
      </ReactLink> */}
    </Stack>
  );
};

export default SettingsSidebar;
