import React from 'react';

import {
  Box,
  Text
} from '@chakra-ui/react';

interface IDataGroup {
  label: string,
  value: string,
}

const DataGroup: React.FC<IDataGroup> = function ({ label, value }) {
  return (
    <Box fontWeight="500" data-testid="data-group">
      <Text color="subGray" fontSize="14px" data-testid="data-group-label">{label}</Text>
      <Text data-testid="data-group-value">{value}</Text>
    </Box>
  );
};

export default DataGroup;
