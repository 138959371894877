import React from 'react';

import {
  Box,
  Text
} from '@chakra-ui/react';
import GaugeChart from 'react-gauge-chart';
import Card from 'Components/elements/Card';

interface IScore {
  score?: number,
  name?: string,
  home?: boolean,
}

const Score: React.FC<IScore> = function ({ score, name, home }) {
  const getColor = (): string[] => {
    if (!score) return ['#A0AEC0', '#A0AEC0', '#A0AEC0', '#A0AEC0', '#A0AEC0', '#A0AEC0'];
    if (score <= 100) return ['#f8584a', '#A0AEC0', '#A0AEC0', '#A0AEC0', '#A0AEC0', '#A0AEC0'];
    if (score <= 300) return ['#f8584a', '#efa741', '#A0AEC0', '#A0AEC0', '#A0AEC0', '#A0AEC0'];
    if (score <= 500) return ['#f8584a', '#efa741', '#d7e53a', '#A0AEC0', '#A0AEC0', '#A0AEC0'];
    if (score <= 700) return ['#f8584a', '#efa741', '#d7e53a', '#78da34', '#A0AEC0', '#A0AEC0'];
    if (score <= 900) return ['#f8584a', '#efa741', '#d7e53a', '#78da34', '#31cc3f', '#A0AEC0'];

    return ['#f8584a', '#efa741', '#d7e53a', '#78da34', '#31cc3f', '#37b582'];
  };

  const translate = (): string => {
    if (!score) return 'excelente';
    if (score <= 100) return 'muito ruim';
    if (score <= 300) return 'ruim';
    if (score <= 500) return 'na média';
    if (score <= 700) return 'bom';
    if (score <= 900) return 'muito bom';

    return 'excelente';
  };

  return (
    <Card title={!home ? 'Score' : ''} noBorder={!!home} testId="score">
      <Box width={home ? '100%' : '350px'} position="relative" mx="auto">
        <GaugeChart
          id="gauge-chart2"
          nrOfLevels={6}
          colors={getColor()}
          percent={0.67}
          arcWidth={0.12}
          hideText
          needleColor="white"
          needleBaseColor="white"
          style={{ fontWeight: 700 }}
          formatTextValue={(value) => value}
        />
        <Box
          textAlign="center"
          position="absolute"
          bottom="0px"
          left="50%"
          transform="translateX(-50%)"
        >
          <Text
            lineHeight="1em"
            color="#1a202c"
            fontWeight="700"
            fontSize="56px"
          >
            {score}
          </Text>
          <Text fontWeight="500" fontSize="18px" color="gray.500">
            de 1000
          </Text>
        </Box>
      </Box>
      <Text
        textAlign="center"
        fontSize="14px"
        fontWeight="500"
        color="gray.500"
      >
        Dataholics v0.1
      </Text>

      <Box h="30px" mt="16px">
        {
          (!!name && !!score)
          && (
            <Text
              fontWeight="600"
              textAlign="center"
              fontSize="18px"
            >
              O score de
              {' '}
              <strong>{name}</strong>
              {' é '}
              {translate()}
            </Text>
          )
        }
      </Box>
    </Card>
  );
};

Score.defaultProps = {
  score: 0,
  name: undefined,
  home: false
};

export default Score;
