import React from 'react';
import {
  Text,
  Container,
  Flex,
  HStack,
  Box,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Stack,
  IconButton
} from '@chakra-ui/react';
import { Link as ReactLink, useLocation } from 'react-router-dom';
import { HiMenu } from 'react-icons/hi';
import { MdLogout } from 'react-icons/md';
import useAuth from 'Core/hooks/useAuth';

interface ICustomLink {
  testId: string;
  href: string;
}

const links = [
  {
    name: 'Consultas',
    link: '/consultas'
  },
  {
    name: 'Pessoa Física',
    link: '/pessoa-fisica'
  }
];

const CustomLink: React.FC<ICustomLink> = function ({ href, testId, children }) {
  return (
    <ReactLink to={href}>
      <Text
        fontWeight="600"
        color="brand.500"
        _hover={{ textDecor: 'none', color: 'brand.600' }}
        data-testid={testId}
        fontFamily="DM Mono"
        textTransform="uppercase"
      >
        {children}
      </Text>
    </ReactLink>
  );
};

const DashboardNavbar: React.FC = function () {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { pathname } = useLocation();
  const { user, logoff } = useAuth();

  return (
    <Box
      position="fixed"
      zIndex="100"
      top="0"
      left="0"
      right="0"
      bgColor="white"
      borderBottom="1px solid"
      borderBottomColor="borderGray"
    >
      <Container maxW="container.lg" position="relative">
        <Flex
          transition="0.2s"
          transitionTimingFunction="ease"
          py="16px"
          color="subGray"
          justifyContent="space-between"
          alignItems="center"
          mb="16px"
        >
          <Text
            fontFamily="bubble"
            color="gray.800"
            fontSize="18px"
            fontWeight="500"
          >
            DATAHOLICS
          </Text>

          <Flex d={{ base: 'none', lg: 'flex' }}>
            <HStack
              spacing="16px"
              color="brand.500"
            >
              <ReactLink to="/configuracoes">
                <Text fontWeight="500">{user.name}</Text>
              </ReactLink>
              <IconButton
                aria-label="logout"
                icon={<MdLogout size="20px" />}
                size="sm"
                variant="ghost"
                onClick={() => logoff()}
              />
            </HStack>
          </Flex>

          <Box d={{ base: 'block', lg: 'none' }}>
            <IconButton
              variant="ghost"
              onClick={onOpen}
              aria-label="open menu"
              icon={<HiMenu size="30px" />}
              color="brand.500"
              colorScheme="brand"
            />
            <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerBody>
                  <Stack
                    h="calc(100% - 50px)"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="24px"
                    spacing="16px"
                  >
                    <CustomLink href="/" testId="mobile-inicio">
                      Início
                    </CustomLink>
                    <CustomLink href="/#sobre" testId="mobile-startups">
                      Sobre
                    </CustomLink>
                    <CustomLink href="/#planos" testId="mobile-investors">
                      Planos
                    </CustomLink>
                    <CustomLink href="/#contato" testId="mobile-investors">
                      Contato
                    </CustomLink>
                    <CustomLink href="/consultas" testId="mobile-investors">
                      Consultas
                    </CustomLink>
                    <CustomLink href="/configuracoes" testId="mobile-investors">
                      Minha conta
                    </CustomLink>
                  </Stack>

                  <Text fontFamily="bubble" textAlign="center" fontWeight="500">
                    DATAHOLICS
                  </Text>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </Box>
        </Flex>
        <HStack
          spacing="40px"
          color="#8D8787"
          fontSize="18px"
          pb="4px"
        >
          {
            links.map((link) => (
              <ReactLink to={link.link} key={link.link}>
                <Text
                  color={link.link === pathname ? 'brand.500' : 'subGray'}
                  fontWeight={link.link === pathname ? '500' : '400'}
                  fontFamily="DM Mono"
                  textTransform="uppercase"
                  fontSize="16px"
                >
                  {link.name}
                </Text>
              </ReactLink>
            ))
          }
        </HStack>
      </Container>
    </Box>
  );
};

export default DashboardNavbar;
