import React from 'react';
import ReactDOM from 'react-dom';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'Core/config/theme';
import AuthProvider from 'Core/context/AuthProvider';
import App from './App';
import './style.css';
import '@ant-design/flowchart/dist/index.css';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
