import React, { useEffect, useState } from 'react';

import {
  Stack,
  Grid,
  Box,
  useToast
} from '@chakra-ui/react';
import DashboardTemplate from 'Components/templates/DashboardTemplate';
import Card from 'Components/elements/Card';
import DataGroup from 'Components/elements/DataGroup';
import QueryModal from 'Components/elements/QueryModal';
import Score from 'Components/elements/Score';
import { useNavigate, useParams } from 'react-router-dom';
import IHistoryItem from 'Core/types/IHistoryItem';
import api from 'Core/services/api';
import Shap from 'Components/elements/Shap';

const Dashboard: React.FC = function () {
  const navigate = useNavigate();

  const { id }: any = useParams();
  const [item, setItem] = useState<any>();

  const toast = useToast();

  useEffect(() => {
    async function getData(): Promise<void> {
      try {
        const { data } = await api.get('/request/history-sicoob');
        data[id].output.score *= 10;
        setItem(data[id]);
      } catch (e) {
        toast({
          title: 'Ops',
          description: 'Algo de errado aconteceu.',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      }
    }
    getData();
  }, [toast]);

  return (

    <DashboardTemplate>
      <Stack spacing="24px">
        <Grid templateColumns={{ base: '1fr', lg: '0.5fr 1fr' }} gap="24px">
          <Box>
            <Score score={item?.output.score} name={item?.input.nome} />

          </Box>
          <Box>
            <Card>
              <Stack spacing="16px">
                <DataGroup label="Nome" value={item?.input.nome} />
                <DataGroup label="CPF" value={item?.input.cpf} />
                <DataGroup label="Data de nascimento" value={item?.input.data_de_nascimento} />
                <DataGroup label="CEP" value={item?.input.cep} />
              </Stack>
            </Card>

            {/* <Box mt="32px" w="250px" ml="auto">
              <QueryModal />
            </Box> */}
          </Box>
        </Grid>
      </Stack>
      <Box data-testid="query-box">

        <Box>
          <Stack spacing="24px">
            <Shap shap={item?.output.shap} />
          </Stack>
        </Box>

      </Box>
    </DashboardTemplate>
  );
};

export default Dashboard;
