import React from 'react';

import {
  Stack
} from '@chakra-ui/react';
import DashboardTemplate from 'Components/templates/DashboardTemplate';
import HistoryBox from 'Components/elements/HistoryBox';
import QueriesInfoBox from 'Components/elements/QueriesInfoBox';

const Dashboard: React.FC = function () {
  return (
    <DashboardTemplate>
      <Stack spacing="24px">
        <QueriesInfoBox />
        <HistoryBox />
      </Stack>
    </DashboardTemplate>
  );
};

export default Dashboard;
