import React from 'react';
import {
  Container,
  Heading, Text, Button, Image, Flex, HStack,
  Grid, Box, IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from '@chakra-ui/react';
import VideoImage from 'Assets/video.webp';
import { ImPlay3 } from 'react-icons/im';

interface IBorder {
  size: string
}

const Border: React.FC<IBorder> = function ({ size }) {
  return (
    <Flex
      position="absolute"
      top="50%"
      left="50%"
      width={size}
      height={size}
      borderRadius="100%"
      transform="translate(-50%, -50%)"
      border="1px solid white"
    />
  );
};

const AIVideo: React.FC = function () {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Container id="sobre" maxW="container.xl" pt={{ base: '20px', lg: '80px' }} pb="80px">
      <Grid
        templateColumns={{ base: '1fr', lg: '1.2fr 0.8fr' }}
        alignItems="center"
        gap="32px"
        display={{ base: 'flex', lg: 'grid' }}
        flexDirection="column-reverse"
      >
        <Box>
          <Heading as="h3" fontWeight="500" fontSize="18px" mb="24px" color="subGray">
            INTELIGÊNCIA ARTIFICIAL
          </Heading>
          <Heading as="h2" fontSize={{ base: '24px', lg: '28px' }} fontWeight="500" mb="24px" color="brand.500">
            Inteligência Artificial identifica clientes com tendência a se tornar inadimplentes
          </Heading>
          <Text fontSize={{ base: '18px', lg: '22px' }} fontWeight="500" mb="36px" color="brand.900">
            A partir da nossa coleta de dados conseguimos descobrir com precisão os maus pagadores.
          </Text>
          <HStack spacing="16px">
            <a href="#saber-mais">
              <Button colorScheme="brand">Saber mais</Button>
            </a>
            <a href="#contato">
              <Button variant="ghost">Entrar em contato</Button>
            </a>
          </HStack>
        </Box>
        <Box mb={{ base: '32px', lg: 0 }}>
          <Box position="relative">
            <Image src={VideoImage} width="100%" />
            <IconButton
              aria-label="play button"
              icon={<ImPlay3 size="40px" />}
              position="absolute"
              top="50%"
              left="50%"
              colorScheme="brand"
              color="white"
              width="100px"
              height="100px"
              borderRadius="100%"
              transform="translate(-50%, -50%)"
              zIndex="10"
              onClick={onOpen}
            />

            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent
                bgColor="transparent"
                maxW="900px"
                boxShadow="none"
              >
                <ModalBody>
                  <div className="video-responsive">
                    <iframe
                      width="853"
                      height="480"
                      src="https://www.youtube.com/embed/i3E0dirwrVQ"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded youtube"
                    />
                  </div>
                </ModalBody>
              </ModalContent>
            </Modal>

            <Border size="150px" />
            <Border size="200px" />
          </Box>
        </Box>
      </Grid>
    </Container>
  );
};

export default AIVideo;
