import React from 'react';
import { Grid, Image, Container } from '@chakra-ui/react';

import Bradesco from 'Assets/bradesco.webp';
import Ciab from 'Assets/ciab.webp';
import Stone from 'Assets/stone.webp';
import Visa from 'Assets/visa.webp';
import Sicoob from 'Assets/sicoob.webp';
import Vivenda from 'Assets/vivenda.webp';

const Partners: React.FC = function () {
  return (
    <Container maxW="container.xl" pt="80px" pb="40px">
      <Grid
        justifyContent="space-around"
        alignItems="center"
        templateColumns={{ base: 'auto auto', lg: 'auto auto auto auto auto auto auto' }}
        columnGap={{ base: '56px', lg: '56px' }}
        rowGap="32px"
      >
        <Image src={Stone} width="128px" mx="auto" />
        <Image src={Sicoob} width="156px" mx="auto" />
        <Image src={Ciab} width="111px" mx="auto" />
        <Image src={Bradesco} width="81px" mx="auto" />
        <Image src={Vivenda} width="106px" mx="auto" />
        <Image src={Visa} width="98px" mx="auto" />
      </Grid>
    </Container>
  );
};

export default Partners;
