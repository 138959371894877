/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box, Container, Button,
  Image, FormControl, FormLabel,
  Grid, Heading, HStack, Input, Stack, Text, Link, Select, useToast
} from '@chakra-ui/react';
import Navbar from 'Components/layout/Navbar';
import Footer from 'Components/layout/Footer';
import Card from 'Components/elements/Card';
import Business from 'Assets/Business.svg';
import { useLocation } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { useMercadopago } from 'react-sdk-mercadopago';
import useAuth from 'Core/hooks/useAuth';
import api from 'Core/services/api';
import IPlan from 'Core/types/IPlans';
import CheckoutComponent from './Checkout';

const Checkout: React.FC = function () {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const planId = query.get('plan');
  const toast = useToast();

  const [plan, setPlan] = useState<IPlan>();

  useEffect(() => {
    async function getData(): Promise<void> {
      try {
        const { data } = await api.get('plans/all');
        setPlan(data.find((p: any) => p._id === planId));
      } catch (e) {
        toast({
          title: 'Ops',
          description: 'Algo de errado aconteceu.',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      }
    }
    getData();
  }, [planId, toast]);

  return (
    plan
      ? (
        <CheckoutComponent plan={plan} />
      ) : <div />
  );
};

export default Checkout;
