import React from 'react';
import {
  Heading, Image, HStack, Box, Text, Stack, Button
} from '@chakra-ui/react';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { Link } from 'react-router-dom';
import useAuth from 'Core/hooks/useAuth';

interface IFeature {
  isWhite?: boolean
}

const Feature: React.FC<IFeature> = function ({ isWhite, children }) {
  return (
    <HStack>
      <IoIosCheckmarkCircleOutline
        size="25px"
        color={isWhite ? 'white' : '#0058FA'}
      />
      <Text
        fontSize="14px"
        color={isWhite ? 'white' : 'brand.900'}
        fontWeight="500"
      >
        {children}

      </Text>
    </HStack>
  );
};

Feature.defaultProps = {
  isWhite: false
};

interface IPlan {
  id: string,
  icon: string,
  title: string,
  subtitle: string,
  price: number,
  subprice: string,
  features: string[],
  isPopular?: boolean,
  defaultPlan: boolean,
  functionPlan: any
}

const Plan: React.FC<IPlan> = function ({
  id, icon, title, subtitle, price, subprice, features, isPopular, defaultPlan, functionPlan
}) {
  const { isAuthenticated } = useAuth();
  return (
    <Box
      p="32px"
      border="1px solid"
      borderColor={isPopular ? 'brand.500' : 'borderGray'}
      position="relative"
      bgColor={isPopular ? 'brand.500' : 'white'}
    >
      {
        !!isPopular
        && (
          <Box
            bgColor="brand.300"
            display="inline"
            color="white"
            fontWeight="600"
            px="8px"
            py="4px"
            fontSize="12px"
            position="absolute"
            top="32px"
            right="32px"
          >
            POPULAR
          </Box>
        )
      }

      <Box mb="40px" h="50px">
        <Image src={icon} width="37px" />
      </Box>

      <Box mb="24px">
        <Heading
          as="h2"
          fontWeight="700"
          color={isPopular ? 'white' : 'brand.900'}
          fontSize="20px"
          mb="8px"
        >
          {title}
        </Heading>
        <Text color={isPopular ? 'white' : 'subGray'} fontWeight="500" fontSize="14px">
          {subtitle}
        </Text>
      </Box>

      <Box mb="32px">
        <Heading
          as="h2"
          fontWeight="700"
          color={isPopular ? 'white' : 'brand.900'}
          fontSize="40px"
        >
          R$
          {price.toLocaleString('pt-br', { minimumFractionDigits: 0 })}
        </Heading>
        <Text
          color={isPopular ? 'white' : 'subGray'}
          fontWeight="500"
          fontSize="14px"
        >
          {subprice}
        </Text>
      </Box>

      <Stack spacing="8px" mb="40px">
        {features.map((feature) => <Feature isWhite={isPopular}>{feature}</Feature>)}
      </Stack>

      {
        !defaultPlan && (
          <Button
            onClick={() => functionPlan(id)}
            colorScheme={isPopular ? 'whiteScheme' : 'brand'}
            w="100%"
            color={isPopular ? 'brand.500' : 'white'}
          >
            Selecionar
          </Button>
        )
      }
      {defaultPlan && (
        <Button
          colorScheme={defaultPlan ? 'whiteScheme' : 'brand'}
          w="100%"
          color={defaultPlan ? 'brand.500' : 'white'}
        >
          Plano Atual
        </Button>
      )}

    </Box>
  );
};

Plan.defaultProps = {
  isPopular: false
};

export default Plan;
