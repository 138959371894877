const Link = {
  baseStyle: {
    fontFamily: 'DM Mono',
    fontWeight: 500,
    _hover: {
      textDecoration: 'none',
      opacity: '0.7'
    }
  }
};

export default Link;
