import React from 'react';
import {
  Box, Heading, Text, Button, Container, Image
} from '@chakra-ui/react';
import HeaderImage from 'Assets/header.webp';
import Navbar from 'Components/layout/Navbar';

const Header: React.FC = function () {
  return (
    <Box id="inicio" bgColor="brand.500" color="white" py={{ base: '124px', lg: '150px' }}>
      <Navbar />
      <Container maxW="container.xl" position="relative">
        <Box maxW={{ base: '100%', lg: '50%' }}>
          <Heading fontSize={{ base: '36px', lg: '56px' }} fontWeight="bold" mb="24px" lineHeight="1em">
            Análise de
            <br />
            crédito inteligente.
          </Heading>
          <Text mb="32px" fontWeight="500">
            Utilize nossa inteligência artificial com base em dados sociais
            e comportamentais para análise e aprovação de crédito em tempo real.
          </Text>
          <a href="#analise">
            <Button
              colorScheme="whiteScheme"
              color="brand.500"
            >
              Fazer análise
            </Button>
          </a>
        </Box>

        <Image
          src={HeaderImage}
          position={{ base: 'relative', lg: 'absolute' }}
          right={{ base: '-10px', lg: 0 }}
          bottom={{ base: '-50px', lg: '-180px' }}
          width={{ base: '100%', lg: '42%' }}
          mx={{ base: 'auto', lg: 0 }}
        />
      </Container>
    </Box>
  );
};

export default Header;
