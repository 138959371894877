import React, { useState } from 'react';
import {
  Text, Container, Flex, Link, HStack, Box,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Stack,
  IconButton
} from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
import { HiMenu } from 'react-icons/hi';

interface ICustomLink {
  testId: string;
  href: string;
}

const CustomLink: React.FC<ICustomLink> = function ({ href, testId, children }) {
  return (
    <ReactLink to={href}>
      <Text
        fontWeight="600"
        color="brand.500"
        _hover={{ textDecor: 'none', color: 'brand.600' }}
        data-testid={testId}
        fontFamily="DM Mono"
        textTransform="uppercase"
      >
        {children}
      </Text>
    </ReactLink>
  );
};

const ScoreiNavbar: React.FC = function () {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isOnTop, setIsOnTop] = useState(true);

  const checkIsOnTop = function (): boolean {
    return document.documentElement.scrollTop < 500;
  };

  document.addEventListener('scroll', () => {
    if (checkIsOnTop()) {
      setIsOnTop(true);
    } else {
      setIsOnTop(false);
    }
  });

  return (
    <Box
      position="fixed"
      zIndex="100"
      top="0"
      left="0"
      right="0"
      bgColor={isOnTop ? 'brand.500' : 'white'}
      borderBottom="1px solid"
      borderBottomColor={isOnTop ? 'transparent' : 'borderGray'}
    >
      <Container maxW="container.lg" position="relative">
        <Flex
          transition="0.2s"
          transitionTimingFunction="ease"
          py={isOnTop ? '24px' : '8px'}
          color="subGray"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text
            fontFamily="DM Mono"
            color={isOnTop ? 'white' : 'brand.500'}
            fontSize="18px"
            fontWeight="500"
          >
            SCOREI
          </Text>

          <Flex d={{ base: 'none', lg: 'flex' }}>
            <HStack
              spacing="40px"
              color={isOnTop ? 'white' : 'brand.500'}
            >
              <Link href="/scorei">INÍCIO</Link>
              <Link href="#consultar">CONSULTAR</Link>
              <Link href="#contato">CONTATO</Link>
            </HStack>

            {/* <ReactLink to="/login">
              <Button
                ml="80px"
                colorScheme={isOnTop ? 'whiteScheme' : 'brand'}
                variant="outline"
                _hover={{
                  bg: isOnTop ? 'white' : 'brand.500',
                  color: isOnTop ? 'brand.500' : 'white'
                }}
              >
                Minha conta
              </Button>
            </ReactLink> */}
          </Flex>

          <Box d={{ base: 'block', lg: 'none' }}>
            <IconButton
              variant="ghost"
              onClick={onOpen}
              aria-label="open menu"
              icon={<HiMenu size="30px" />}
              color={isOnTop ? 'white' : 'brand.900'}
              colorScheme="brand"
            />
            <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerBody>
                  <Stack
                    h="calc(100% - 50px)"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="24px"
                    spacing="16px"
                  >
                    <CustomLink href="#" testId="mobile-inicio">
                      Início
                    </CustomLink>
                    <CustomLink href="#consultar" testId="mobile-startups">
                      Consultar
                    </CustomLink>
                    <CustomLink href="#contato" testId="mobile-investors">
                      Minha conta
                    </CustomLink>
                  </Stack>

                  <Text fontFamily="DM Mono" textAlign="center" fontWeight="500">
                    DATAHOLICS
                  </Text>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default ScoreiNavbar;
