import React from 'react';
import {
  Box, Grid, Container, Heading, Text, Button, Image
} from '@chakra-ui/react';
import WomanHeader from 'Assets/WomanHeader.png';
import ScoreiNavbar from './ScoreiNavbar';

const ScoreiHeader: React.FC = function () {
  return (
    <Box pt="128px" bgColor="brand.500">
      <ScoreiNavbar />
      <Container maxW="container.lg">
        <Grid templateColumns={{ base: '1fr', lg: '1fr 1fr' }} spacing="24px">
          <Box pb="80px" color="white">
            <Heading
              fontSize="48px"
              lineHeight={{ base: '1.1em', lg: '1.2em' }}
              mb={{ base: '16px', lg: '16px' }}
            >
              Consulta de CPF online e grátis?
            </Heading>
            <Text fontWeight="500">
              Consultando seu CPF no Scorei você pode ver seu score de pontuação e
              receber ofertas de crédito personalizadas.
            </Text>

            <a href="#consultar">
              <Button
                mt="32px"
                colorScheme="whiteScheme"
                color="brand.500"
                w="270px"
                size="lg"
              >
                Consultar CPF
              </Button>
            </a>
          </Box>
          <Image d={{ base: 'none', lg: 'block' }} mx="auto" maxW="330px" transform="translateY(0px)" src={WomanHeader} />
        </Grid>
      </Container>
    </Box>
  );
};

export default ScoreiHeader;
