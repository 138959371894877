import {
  Box, Button, Container, Heading, Text
} from '@chakra-ui/react';
import Footer from 'Components/layout/Footer';
import Navbar from 'Components/layout/Navbar';
import React from 'react';
import { Link } from 'react-router-dom';

const Success: React.FC = function () {
  return (
    <Box>
      <Navbar />
      <Box
        bgColor="brand.500"
        pt="128px"
        pb="96px"
        color="white"
      >
        <Container maxW="container.xl">
          <Heading fontSize={{ base: '32px', lg: '55px' }}>Assinatura Dataholics</Heading>
          <Text>Insira seus dados de pagamento para finalizar o processo de assinatura</Text>
        </Container>
      </Box>

      <Container maxW="container.lg" py="128px">
        <Box textAlign="center">
          <Heading as="h2" mb="8px">Pagamento confirmado!</Heading>
          <Text mb="32px">Você já está livre para consultar os scores.</Text>
          <Link to="/pessoa-fisica">
            <Button colorScheme="brand">Fazer primeira consulta</Button>
          </Link>
        </Box>
      </Container>

      <Footer />
    </Box>
  );
};

export default Success;
