import React from 'react';
import {
  Heading, Image, HStack, Box, Text, Stack, Button
} from '@chakra-ui/react';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { Link } from 'react-router-dom';
import useAuth from 'Core/hooks/useAuth';

interface ICard {
  data: any,
  defaultCard: boolean,
  functionCard: any,
  functionDelete: any
}

const CreditCard: React.FC<ICard> = function ({
  data, defaultCard, functionCard, functionDelete
}) {
  return (
    <Box
      p="32px"
      border="1px solid"
      borderColor="borderGray"
      position="relative"
      bgColor="white"
    >
      <Box mb="24px">

        <Text color="subGray" fontWeight="500" fontSize="14px" textTransform="uppercase">
          {data.card.brand}
        </Text>
        <Heading
          as="h3"
          fontWeight="700"
          color="brand.900"
          fontSize="20px"
          mb="8px"
        >
          {data.billing_details.name}
        </Heading>
        <Heading
          as="h3"
          fontWeight="700"
          color="brand.900"
          fontSize="20px"
          mb="8px"
        >
          ****.****.****.
          {data.card.last4}
        </Heading>
        <Text color="subGray" fontWeight="500" fontSize="14px" textAlign="right">

          {`Válido até ${data.card.exp_month}/${data.card.exp_year}`}

        </Text>
      </Box>
      {
        !defaultCard && (
          <Button
            colorScheme="brand"
            w="100%"
            color="white"
            onClick={() => functionCard(data.id)}
          >
            Definir como padrão
          </Button>
        )
      }
      {
        !defaultCard && (
          <Button
            marginTop="5px"
            background="red"
            w="100%"
            color="white"
            onClick={() => functionDelete(data.id)}
          >
            Excluir Cartão
          </Button>
        )
      }

    </Box>
  );
};

export default CreditCard;
