import React, { useState } from 'react';
import {
  Box,
  Grid,
  Heading,
  Text, Input,
  Button,
  Flex,
  FormControl,
  IconButton,
  useToast,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  FormErrorMessage
} from '@chakra-ui/react';
import { BsArrowLeft, BsArrowRightShort } from 'react-icons/bs';
import {
  Link as ReactLink, useNavigate, useLocation, useParams
} from 'react-router-dom';
import api from 'Core/services/api';
import useAuth from 'Core/hooks/useAuth';
import * as yup from 'yup';
import {
  Field, FieldProps, Form, Formik
} from 'formik';
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';
import { FaKey } from 'react-icons/fa';

function useQuery(): URLSearchParams {
  const { search } = useLocation();

  return new URLSearchParams(search);
}

const schema = yup.object().shape({
  password: yup.string().required()
});

type FormTypes = {
  password: string
}

const InsertPassword: React.FC = function () {
  const { token: recoverToken } = useParams();
  console.log(recoverToken);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const toast = useToast();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submit = async (values: FormTypes, actions: any): Promise<void> => {
    try {
      const { data } = await api.post('/recoverPassword/change', {
        password: values.password,
        token: recoverToken
      });

      const { token, user } = data;

      navigate('/login');
      toast({
        title: `Bem-vindo(a), ${user.name}`,
        description: 'Sua senha foi alterada! Vamos começar sua jornada?',
        status: 'success',
        duration: 9000,
        isClosable: true
      });
    } catch (err) {
      toast({
        title: 'Acesso inválido',
        description: 'Verifique seu e-mail e sua senha',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
    actions.setSubmitting(false);
  };

  return (
    recoverToken ? (

      <Box>
        <ReactLink to="/">
          <IconButton
            aria-label="return"
            color="white"
            icon={<BsArrowLeft size="24px" />}
            position="absolute"
            top="32px"
            left="32px"
            borderRadius="100px"
            bgColor="transparent"
            _hover={{ bgColor: 'rgba(255,255,255,.1)' }}
          />
        </ReactLink>
        <Grid templateColumns={{ base: '1fr', lg: 'calc(100% - 500px) 500px' }} minH="100vh">
          <Flex color="white" bg="brand.500" alignItems="center" p="80px" d={{ base: 'none', lg: 'flex' }}>
            <Box>
              <Heading as="h1" fontWeight="700" fontSize={{ base: '28px', lg: '50px' }} lineHeight="1.1em" mb="16px">
                Acesso seguro
                <br />
                ao painel de análises.
              </Heading>
              <Text maxW="700px" fontWeight="500">
                Utilize nossa inteligência artificial com base em dados sociais
                e comportamentais para análise e aprovação de crédito em tempo real.
              </Text>
            </Box>
          </Flex>
          <Flex alignItems="center" p="80px" display="inherit">
            <Heading mb="8px" color="brandPurple.500">
              Nova senha
            </Heading>
            <Text mb="32px">Insira sua nova senha para ser alterada</Text>

            <Formik
              initialValues={{
                password: ''
              }}
              onSubmit={(values, actions) => {
                submit(values, actions);
              }}
              validationSchema={schema}
            >
              {(props) => (
                <Form>
                  <Field name="password">
                    {({ field, form }: FieldProps) => (

                      <FormControl
                        id="password"
                        isInvalid={
                          !!(form.errors.password && form.touched.password)
                        }
                        isRequired
                        marginBottom="16px"
                      >
                        {' '}
                        <InputGroup>
                          <InputLeftElement
                            paddingLeft="8px"
                            height="60px"
                            color="grayPurple"
                          >
                            <FaKey size="18px" />
                          </InputLeftElement>
                          <Input
                            {...field}
                            paddingLeft="48px"
                            bg="white"
                            border="1px solid"
                            borderColor="grayLight"
                            color="grayPurple"
                            h="60px"
                            borderRadius="8px"
                            placeholder="Nova senha"
                            _placeholder={{ color: 'grayPurple' }}
                            fontWeight="medium"
                            fontSize="15px"
                            type={showPassword ? 'text' : 'password'}
                          />
                          <InputRightElement height="60px" paddingRight="8px">
                            <IconButton
                              aria-label="show password"
                              onClick={() => setShowPassword(!showPassword)}
                              icon={
                                showPassword ? (
                                  <IoIosEyeOff size="25px" />
                                ) : (
                                  <IoIosEye size="25px" />
                                )
                              }
                              size="sm"
                              color="brandPurple.500"
                              variant="ghost"
                              _focus={{ outline: 'none' }}
                            />
                          </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>
                          Insira uma senha forte
                        </FormErrorMessage>
                      </FormControl>

                    )}
                  </Field>
                  <Button
                    isLoading={props.isSubmitting}
                    w="100%"
                    type="submit"
                    colorScheme="brand"
                    height="60px"
                    leftIcon={(
                      <Box opacity="0">
                        <BsArrowRightShort size="30px" />
                      </Box>
                    )}
                    rightIcon={<BsArrowRightShort size="30px" />}
                    justifyContent={
                      props.isSubmitting ? 'center' : 'space-between'
                    }
                    mb="16px"
                  >
                    Alterar senha
                  </Button>

                </Form>
              )}
            </Formik>

          </Flex>
        </Grid>
      </Box>
    )
      : <Box />
  );
};

export default InsertPassword;
