/* eslint-disable indent */
import React from 'react';
import {
  Box,
  Button,
  Image,
  Text,
  Flex
} from '@chakra-ui/react';
import Vivenda from 'Assets/vivenda.webp';

const Offer: React.FC<{
  name: string,
  value: number,
  plots: number,
  valuePlots: number
}> = function ({
  name, value, plots, valuePlots
}) {
    return (
      <Box p="24px" border="1px solid" borderColor="gray.100">
        <Flex alignItems="center">
          <Image src={Vivenda} h="100px" mr="16px" />
          {/* <Text
          mb="16px"
          fontSize="20px"
          fontWeight="700"
          color="brand.500"
        >
          {name}
        </Text> */}
          <Box>
            <Text fontSize="14px">
              Você tem
              {' '}
              <Text
                fontSize="24px"
                color="green.400"
                fontWeight="700"
              >
                R$
                {value.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
              </Text>
              {' '}
              de crédito disponível
              {' '}
              em
              {' '}
              <strong>
                {plots}
                x
              </strong>
              {' '}
              de
              {' '}
              <strong>
                R$
                {valuePlots.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
              </strong>
            </Text>
          </Box>
        </Flex>

        <Button
          colorScheme="brand"
          mt="16px"
          size="sm"
          w="100%"
        >
          Começar reforma
        </Button>

      </Box>
    );
  };

export default Offer;
