import React, { useState } from 'react';

import {
  Box,
  Grid,
  Stack
} from '@chakra-ui/react';
import Score from 'Components/elements/Score';
import QueryBoxForm from 'Components/elements/QueryBoxForm';
import IScore from 'Core/types/IScore';
import CreditOffers from 'Components/elements/CreditOffers';
import Card from 'Components/elements/Card';
import Shap from './Shap';

interface IQueryBox {
  type: 'person' | 'business'
}

const QueryBox: React.FC<IQueryBox> = function ({ type }) {
  const [name, setName] = useState<string>();
  const [score, setScore] = useState<IScore>();

  return (
    <>
      <Box data-testid="query-box">
        <Grid
          templateColumns={{ base: '1fr', lg: '0.9fr 1fr' }}
          gap="24px"
        >
          <Box>
            <QueryBoxForm type={type} setScore={setScore} setName={setName} />
          </Box>
          <Stack spacing="24px">
            <Score score={(score?.score.value || 0) * 10} name={name} />
          </Stack>
        </Grid>
      </Box>
      <Box data-testid="query-box">

        <Box>
          <Stack spacing="24px">
            <Shap shap={score?.score.shap} />
          </Stack>
        </Box>

      </Box>
    </>
  );
};

export default QueryBox;
