import React, { useEffect, useState } from 'react';

import {
  Stack, Text, Button, Grid, Box, Table, Thead, Tbody, Th, Td, Tr, useToast
} from '@chakra-ui/react';
import DashboardTemplate from 'Components/templates/DashboardTemplate';
import Card from 'Components/elements/Card';
import api from 'Core/services/api';

const API: React.FC = function () {
  const [key, setKey] = useState<string>();

  const [submiting, setSubmiting] = useState<boolean>(false);

  const toast = useToast();

  useEffect(() => {
    async function getData(): Promise<void> {
      try {
        const { data } = await api.get('/request/key');

        setKey(data.token);
      } catch (e) {
        toast({
          title: 'Ops',
          description: 'Algo de errado aconteceu.',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      }
    }
    getData();
  }, [toast]);

  const refresh = async function (): Promise<void> {
    try {
      setSubmiting(true);
      const { data } = await api.patch('/request/key');

      setKey(data.token);
      setSubmiting(false);
    } catch (e) {
      toast({
        title: 'Ops',
        description: 'Algo de errado aconteceu.',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
      setSubmiting(false);
    }
  };

  return (
    <DashboardTemplate>
      <Stack spacing="24px">
        <Grid gap="24px" templateColumns={{ base: '1fr', lg: '320px auto' }}>
          <Box>
            <Card title="API Details">
              <Text>API Bearer Token</Text>
              <Text fontSize="14px" fontWeight="600">{key || 'Carregando token...'}</Text>
              <Button
                colorScheme="brand"
                mt="16px"
                onClick={() => refresh()}
                isLoading={submiting}
              >
                Refresh token
              </Button>
            </Card>
          </Box>

          <Card title="Use cases">
            <Stack spacing="32px">
              <Box>
                <Text>Person analysis</Text>

                <Box mt="16px">
                  <Text fontWeight="700">POST</Text>
                  <Text
                    d="block"
                    p="8px"
                    bg="gray.100"
                    border="1px solid"
                    borderColor="gray.200"
                    mt="8px"
                    mb="16px"
                  >
                    https://www.dataholics.herokuapp.com/request/person/score
                  </Text>
                  <Table size="sm">
                    <Thead>
                      <Th>Param</Th>
                      <Th>Type</Th>
                      <Th>Description</Th>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>name</Td>
                        <Td>string</Td>
                        <Td>Person name</Td>
                      </Tr>
                      <Tr>
                        <Td>cpf</Td>
                        <Td>string</Td>
                        <Td>Person document</Td>
                      </Tr>
                      <Tr>
                        <Td>cep</Td>
                        <Td>string</Td>
                        <Td>Zip code</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>

                <Text
                  mt="32px"
                  mb="8px"
                >
                  Exemplo retorno
                </Text>
                <Box
                  p="8px"
                  bg="gray.50"
                  border="1px solid"
                  borderColor="gray.100"
                >
                  {
                    '{ score: { value: 960 } }'
                  }
                </Box>
              </Box>

              <hr />

              <Box>
                <Text>Business analysis</Text>

                <Box mt="16px">
                  <Text fontWeight="700">POST</Text>
                  <Text
                    d="block"
                    p="8px"
                    bg="gray.100"
                    border="1px solid"
                    borderColor="gray.200"
                    mt="8px"
                    mb="16px"
                  >
                    https://www.dataholics.herokuapp.com/request/business/score
                  </Text>
                  <Table size="sm">
                    <Thead>
                      <Th>Param</Th>
                      <Th>Type</Th>
                      <Th>Description</Th>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>cnpj</Td>
                        <Td>string</Td>
                        <Td>Business document</Td>
                      </Tr>
                      <Tr>
                        <Td>cep</Td>
                        <Td>string</Td>
                        <Td>Zip code</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>

                <Text
                  mt="32px"
                  mb="8px"
                >
                  Exemplo retorno
                </Text>
                <Box
                  p="8px"
                  bg="gray.50"
                  border="1px solid"
                  borderColor="gray.100"
                >
                  {
                    '{ score: { value: 960 } }'
                  }
                </Box>
              </Box>
            </Stack>
          </Card>
        </Grid>
      </Stack>
    </DashboardTemplate>
  );
};

export default API;
