import React from 'react';

import {
  Stack
} from '@chakra-ui/react';
import DashboardTemplate from 'Components/templates/DashboardTemplate';
import QueryBox from 'Components/elements/QueryBox';

const Person: React.FC = function () {
  return (
    <DashboardTemplate>
      <Stack spacing="24px">
        <QueryBox type="person" />
      </Stack>
    </DashboardTemplate>
  );
};

export default Person;
