import React from 'react';

import {
  Box,
  Heading
} from '@chakra-ui/react';

interface ICard {
  title?: string,
  testId?: string,
  noBorder?: boolean
}

const Card: React.FC<ICard> = function ({
  title, testId, children, noBorder
}) {
  return (
    <Box
      border={!noBorder ? '1px solid' : 'none'}
      borderColor="borderGray"
      p="24px"
      bgColor="white"
      data-testid={testId || 'card'}
    >
      {title
        && (
          <Heading
            fontWeight="600"
            color="subGray"
            fontSize="20px"
            data-testid="card-title"
          >
            {title}
          </Heading>
        )}
      <Box
        pt={title ? '32px' : '0'}
        // pb="24px"
        data-testid="card-content"
      >
        {children}
      </Box>
    </Box>
  );
};

Card.defaultProps = {
  title: undefined,
  testId: undefined,
  noBorder: false
};

export default Card;
