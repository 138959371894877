import React from 'react';

import { Box, Container } from '@chakra-ui/react';

import DashboardNavbar from 'Components/layout/dashboard/Navbar';

const DashboardTemplate: React.FC = function ({ children }) {
  return (
    <Box>
      <DashboardNavbar />
      <Box mt="105px" bgColor="#fafafa" minH="calc(100vh - 110px)">
        <Container maxW="container.lg" py="48px">
          {children}
        </Container>
      </Box>
    </Box>
  );
};

export default DashboardTemplate;
