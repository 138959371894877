import React from 'react';
import IRoute from 'Core/types/IRoute';

import Home from 'Pages/Home';
import Login from 'Pages/Login';
import Register from 'Pages/Register';
import Dashboard from 'Pages/dashboard/Dashboard';
import Settings from 'Pages/dashboard/Settings';
import Query from 'Pages/dashboard/Query';
import Person from 'Pages/dashboard/Person';
import Business from 'Pages/dashboard/Business';
import API from 'Pages/dashboard/API';
import Payment from 'Pages/dashboard/Payment';
import Invoices from 'Pages/dashboard/Invoices';
import Scorei from 'Pages/Scorei';
import Checkout from 'Pages/checkout';
import Message from 'Pages/checkout/Message';
import Success from 'Pages/checkout/Success';
import ForgetPass from 'Pages/ForgetPass';
import InsertCode from 'Pages/RecoverPass';
import InsertPassword from 'Pages/InsertPass';

const routes: IRoute[] = [
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/scorei',
    element: <Scorei />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/register',
    element: <Register />
  },
  {
    path: '/forget-pass',
    element: <ForgetPass />
  },
  {
    path: '/pessoa-fisica',
    element: <Person />,
    private: true
  },
  {
    path: '/pessoa-juridica',
    element: <Business />,
    private: true
  },
  {
    path: '/api',
    element: <API />,
    private: true
  },
  {
    path: '/consultas',
    element: <Dashboard />,
    private: true
  },
  {
    path: '/dashboard/query',
    element: <Query />,
    private: true
  },
  {
    path: '/dashboard/query/:id',
    element: <Query />,
    private: true
  },
  {
    path: '/configuracoes',
    element: <Settings />,
    private: true
  },
  {
    path: '/configuracoes/pagamento',
    element: <Payment />,
    private: true
  },
  {
    path: '/configuracoes/recibos',
    element: <Invoices />,
    private: true
  },
  {
    path: '/checkout',
    element: <Checkout />
  },
  {
    path: '/checkout/message',
    element: <Message />
  },
  {
    path: '/checkout/success',
    element: <Success />
  },
  {
    path: '/esqueci-senha/:token/inserir-codigo',
    exact: true,
    element: <InsertCode />
  },
  {
    path: '/esqueci-senha/:token/inserir-senha',
    exact: true,
    element: <InsertPassword />
  }
];

export default routes;
