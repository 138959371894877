import React from 'react';
import {
  Container, Heading, Button, Image, HStack, Grid, Box
} from '@chakra-ui/react';
import IAImage from 'Assets/ia.webp';
import { Link } from 'react-router-dom';

const Analysis: React.FC = function () {
  return (
    <Box bg="brand.100">
      <Container maxW="container.xl" pt="80px" pb={{ base: '40px', lg: '80px' }}>
        <Grid
          templateColumns={{ base: '1fr', lg: '1.2fr 0.8fr' }}
          alignItems="center"
          gap={{ base: '56px', lg: '32px' }}
          display={{ base: 'flex', lg: 'grid' }}
          flexDirection="column-reverse"
        >
          <Box>
            <Heading as="h3" fontWeight="500" fontSize="18px" mb="24px" color="subGray">
              MODELO DE CRÉDITO CUSTOMIZADO
            </Heading>
            <Heading as="h2" fontSize={{ base: '24px', lg: '28px' }} fontWeight="500" mb="24px" color="brand.500">
              Use seu histórico de crédito
              para criar um modelo de análise de risco customizado para seu público.
            </Heading>
            <HStack spacing="16px">
              {/* <Button colorScheme="brand">Ver Documentação</Button> */}
              <a href="#contato">
                <Button colorScheme="brand">Fale conosco</Button>
              </a>
            </HStack>
          </Box>
          <Box mb={{ base: '56px', lg: 0 }}>
            <Image src={IAImage} width="100%" />
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default Analysis;
