import React, { useState } from 'react';

import Card from 'Components/elements/Card';
import {
  Box, Stack, FormControl, FormLabel, Input, Button, useToast
} from '@chakra-ui/react';
import useAuth from 'Core/hooks/useAuth';
import IContactDataForm from 'Core/types/IContactDataForm';
import api from 'Core/services/api';

const ContactDataForm: React.FC<IContactDataForm> = function ({ user }) {
  const [email, setEmail] = useState<string>(user.email);
  const [phone, setPhone] = useState<string | undefined>(user.phone);

  const [submiting, setSubmiting] = useState(false);

  const toast = useToast();

  const { updateUser } = useAuth();

  const submit = async function (): Promise<void> {
    try {
      if (!email) {
        toast({
          title: 'Informe seu email.',
          description: 'Informe seu email para completar a atualização.',
          status: 'warning',
          duration: 9000,
          isClosable: true
        });

        return;
      }

      setSubmiting(true);
      const { data } = await api.patch('user', {
        phone,
        email
      });

      updateUser(data.user);

      setSubmiting(false);
    } catch (e) {
      toast({
        title: 'Ops',
        description: 'Algo de errado aconteceu.',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
      setSubmiting(false);
    }
  };

  return (
    <Card title="Dados de contato" testId="contact-data-form">
      <Box>
        <Stack spacing="24px">
          <FormControl id="email">
            <FormLabel mb="8px">Seu email</FormLabel>
            <Input
              type="text"
              placeholder="Seu nome..."
              variant="custom"
              size="lg"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl id="telefone">
            <FormLabel mb="8px">Seu telefone</FormLabel>
            <Input
              type="text"
              placeholder="Seu telefone..."
              variant="custom"
              size="lg"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </FormControl>
        </Stack>

        <Button
          colorScheme="brand"
          mt="24px"
          onClick={() => submit()}
          isLoading={submiting}
        >
          Salvar alterações
        </Button>
      </Box>
    </Card>
  );
};

export default ContactDataForm;
