import IUser, { DEFAULT_VALUES as USER_DEFAULT_VALUES } from './IUser';

interface IAuthContext {
  token: string,
  user: IUser,
  authenticate: (user: IUser, _token: string) => void,
  logoff: () => void,
  isAuthenticated: () => boolean,
  updateUser: (_user: IUser) => void
}

export const DEFAULT_VALUES = {
  token: '',
  user: USER_DEFAULT_VALUES,
  authenticate: (): void => undefined,
  logoff: (): void => undefined,
  isAuthenticated: (): boolean => false,
  updateUser: (): void => undefined
};

export default IAuthContext;
