import {
  Box, Button, Container, Heading, Text, Spinner
} from '@chakra-ui/react';
import Footer from 'Components/layout/Footer';
import Navbar from 'Components/layout/Navbar';
import React from 'react';
import { Link } from 'react-router-dom';

const Message: React.FC = function () {
  return (
    <Box>
      <Navbar />
      <Box
        bgColor="brand.500"
        pt="128px"
        pb="96px"
        color="white"
      >
        <Container maxW="container.xl">
          <Heading fontSize={{ base: '32px', lg: '55px' }}>Assinatura Dataholics</Heading>
          <Text>Insira seus dados de pagamento para finalizar o processo de assinatura</Text>
        </Container>
      </Box>

      <Container maxW="container.lg" py="128px">
        <Box textAlign="center">
          <Heading as="h2" mb="8px">Ordem de pagamento enviada</Heading>
          <Text mb="32px">Aguarde enquanto processamos seu pagamento.</Text>

          <Spinner />
        </Box>
      </Container>

      <Footer />
    </Box>
  );
};

export default Message;
