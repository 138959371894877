const Input = {
  baseStyle: {
    field: {
      fontFamily: 'DM Mono',
      fontWeight: 500,
      border: '1px solid',
      borderColor: 'borderGray',
      borderRadius: '0px'
    }
  },
  variants: {
    custom: {
      field: {
        fontFamily: 'DM Mono',
        fontWeight: 500,
        border: '1px solid',
        borderColor: 'borderGray',
        borderRadius: 0
      }
    }
  }
};

export default Input;
