import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Heading,
  Text, Input,
  Button, Flex, FormControl, FormLabel,
  Link, IconButton, useToast, Checkbox,
  PinInputField, HStack, PinInput
} from '@chakra-ui/react';
import { BsArrowLeft } from 'react-icons/bs';
import {
  Link as ReactLink, useNavigate, useLocation, useParams
} from 'react-router-dom';
import api from 'Core/services/api';
import useAuth from 'Core/hooks/useAuth';
import { IoHome } from 'react-icons/io5';

function useQuery(): URLSearchParams {
  const { search } = useLocation();

  return new URLSearchParams(search);
}

const InsertCode: React.FC = function () {
  const toast = useToast();
  const { token } = useParams();
  const [email, setEmail] = useState<string>();

  const [submiting, setSubmiting] = useState<boolean>(false);

  const { authenticate, isAuthenticated } = useAuth();

  const navigate = useNavigate();

  const query = useQuery();
  const redirect = query.get('redirect');

  const [pin, setPin] = useState('');
  const [loading, setLoading] = useState(false);

  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    setTimeout(() => {
      if (seconds > 0) setSeconds(seconds - 1);
    }, 1000);
  }, [seconds]);

  const submit = useCallback(async (code): Promise<void> => {
    try {
      setLoading(true);
      console.log(code);
      const data = await api.post('/recoverPassword/verify', {
        code,
        token
      });

      if (data.data.success) {
        navigate(`/esqueci-senha/${token}/inserir-senha`);
        toast({
          title: 'Código validado',
          description: 'Por favor, insira sua nova senha',
          status: 'success',
          duration: 9000,
          isClosable: true
        });
      } else {
        toast({
          title: 'Código inválido',
          description: 'Verifique o código enviado para seu email',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      }
    } catch (err) {
      toast({
        title: 'Código inválido',
        description: 'Verifique o código enviado para seu email',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(pin);
    if (pin.length === 6) submit(pin);
  }, [pin, submit]);

  const [resending, setResending] = useState(false);

  const resendEmail = async (): Promise<void> => {
    setResending(true);
    await api.post('/recoverPassword/resend', {
      token
    });

    toast({
      title: 'Código enviado',
      description: 'Por favor, verifique seu e-mail',
      status: 'success',
      duration: 9000,
      isClosable: true
    });

    setSeconds(30);
    setResending(false);
  };

  return (

    <Box>
      <ReactLink to="/">
        <IconButton
          aria-label="return"
          color="white"
          icon={<BsArrowLeft size="24px" />}
          position="absolute"
          top="32px"
          left="32px"
          borderRadius="100px"
          bgColor="transparent"
          _hover={{ bgColor: 'rgba(255,255,255,.1)' }}
        />
      </ReactLink>
      <Grid templateColumns={{ base: '1fr', lg: 'calc(100% - 500px) 500px' }} minH="100vh">
        <Flex color="white" bg="brand.500" alignItems="center" p="80px" d={{ base: 'none', lg: 'flex' }}>
          <Box>
            <Heading as="h1" fontWeight="700" fontSize={{ base: '28px', lg: '50px' }} lineHeight="1.1em" mb="16px">
              Acesso seguro
              <br />
              ao painel de análises.
            </Heading>
            <Text maxW="700px" fontWeight="500">
              Utilize nossa inteligência artificial com base em dados sociais
              e comportamentais para análise e aprovação de crédito em tempo real.
            </Text>
          </Box>
        </Flex>
        <Flex alignItems="center" p="80px" display="inherit">
          <Heading mb="8px" color="brandPurple.500">
            Inserir código
          </Heading>
          <Text mb="32px">
            Insira o código de confirmação enviado para seu e-mail.
          </Text>

          <HStack justifyContent="center">
            <PinInput
              size="lg"
              onChange={(val) => setPin(val)}
              value={pin}
              isDisabled={loading}
            >
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>
          <Box textAlign="center" mt="24px">
            <Button
              colorScheme="brandPurple"
              variant="ghost"
              disabled={seconds > 0}
              onClick={resendEmail}
              isLoading={resending}
            >
              Enviar novamente
              {' '}
              {seconds > 0 && `(${seconds})`}
            </Button>
          </Box>
        </Flex>
      </Grid>
    </Box>
  );
};
export default InsertCode;
