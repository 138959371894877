import React, { useEffect, useState } from 'react';

import {
  Box, Grid, Stack, Th, Thead, Tr, Table, Tbody, Td, Link, useToast
} from '@chakra-ui/react';
import DashboardTemplate from 'Components/templates/DashboardTemplate';
import SettingsSidebar from 'Components/layout/dashboard/SettingsSidebar';
import Card from 'Components/elements/Card';
import api from 'Core/services/api';
import IInvoice from 'Core/types/IInvoice';

const Invoices: React.FC = function () {
  const [invoices, setInvoices] = useState<IInvoice[]>([]);

  const toast = useToast();

  useEffect(() => {
    async function getData(): Promise<void> {
      try {
        const { data } = await api.get('/payment/history');

        setInvoices(data.invoices);
      } catch (e) {
        toast({
          title: 'Ops',
          description: 'Algo de errado aconteceu.',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      }
    }
    getData();
  }, [toast]);

  return (
    <DashboardTemplate>
      <Grid templateColumns={{ base: '1fr', lg: '200px auto' }} gap="24px">
        <Box>
          <SettingsSidebar />
        </Box>
        <Stack spacing="24px">
          <Card title="Recibos">
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>Data</Th>
                  <Th>Valor</Th>
                  <Th>Download</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  invoices.map((invoice, index) => (
                    <Tr key={invoice.id}>
                      <Td>{index + 1}</Td>
                      <Td>{invoice.date}</Td>
                      <Td>
                        R$
                        {invoice.value}
                      </Td>
                      <Td>
                        <Link href={invoice.link} color="brand.500">Download PDF</Link>
                      </Td>
                    </Tr>
                  ))
                }
              </Tbody>
            </Table>
          </Card>
        </Stack>
      </Grid>
    </DashboardTemplate>
  );
};

export default Invoices;
