const colors = {
  brand: {
    100: '#EFF9FC',
    300: '#76D1CB',
    500: '#0058FF',
    600: '#034DD6',
    900: '#090A39'
  },
  secondary: {
    500: '#76D1CB'
  },
  whiteScheme: {
    500: 'white',
    600: '#EFF9FC'
  },
  subGray: '#6A6F8B',
  borderGray: '#E6E6E6'
};

export default colors;
