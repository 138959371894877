import React, { useEffect, useState } from 'react';

import {
  Box, Grid, Stack, useToast
} from '@chakra-ui/react';
import DashboardTemplate from 'Components/templates/DashboardTemplate';
import PersonalDataForm from 'Components/elements/PersonalDataForm';
import SettingsSidebar from 'Components/layout/dashboard/SettingsSidebar';
import ContactDataForm from 'Components/elements/ContactDataForm';
import IUser from 'Core/types/IUser';
import api from 'Core/services/api';
import useAuth from 'Core/hooks/useAuth';

const Settings: React.FC = function () {
  const { user: defaultUser } = useAuth();
  const [user, setUser] = useState<IUser>(defaultUser);

  const toast = useToast();

  useEffect(() => {
    async function getData(): Promise<void> {
      try {
        const { data } = await api.get('/authenticate/user');

        setUser(data.user);
      } catch (e) {
        toast({
          title: 'Ops',
          description: 'Algo de errado aconteceu.',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      }
    }
    getData();
  }, [toast]);

  return (
    <DashboardTemplate>
      <Grid templateColumns={{ base: '1fr', lg: '200px auto' }} gap="24px">
        <Box>
          <SettingsSidebar />
        </Box>
        <Stack spacing="24px">
          <PersonalDataForm user={user} />
          <ContactDataForm user={user} />
        </Stack>
      </Grid>
    </DashboardTemplate>
  );
};

export default Settings;
