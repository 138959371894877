import React, { useState } from 'react';
import {
  Container, Button, Grid, Box,
  FormControl,
  Stack,
  Input,
  Heading,
  FormLabel,
  useToast,
  Checkbox
} from '@chakra-ui/react';
import Score from 'Components/elements/Score';
import InputMask from 'react-input-mask';
import scoreiApi from 'Core/services/scoreiApi';
import IOffer from 'Core/types/IOffer';
import CreditOffers from 'Components/elements/CreditOffers';

const PFForm: React.FC<{
  submit: (name: string, email: string, cpf: string, cep: string,
    sallary: string) => Promise<boolean>,
  loading: boolean,
}> = function ({ submit, loading }) {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [cpf, setCpf] = useState<string>('');
  const [cep, setCep] = useState<string>('');
  const [sallary, setSallary] = useState('');
  const [agreed, setAgreed] = useState<boolean>(false);

  const toast = useToast();

  return (
    <Stack
      spacing="24px"
      data-testid="query-modal-form"
      mt="24px"
      minH="360px"
    >
      <FormControl data-testid="query-modal-form-name">
        <FormLabel mb="8px">Nome completo</FormLabel>
        <Input
          variant="custom"
          size="md"
          data-testid="name-input"
          placeholder="Nome completo..."
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
      </FormControl>
      <FormControl data-testid="query-modal-form-name">
        <FormLabel mb="8px">Email</FormLabel>
        <Input
          variant="custom"
          size="md"
          data-testid="name-input"
          placeholder="Nome completo..."
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
      </FormControl>
      <FormControl data-testid="query-modal-form-cpf">
        <FormLabel mb="8px">CPF</FormLabel>
        <InputMask
          mask="999.999.999-99"
          onChange={(e) => setCpf(e.target.value)}
          value={cpf}
        >
          {(inputProps: unknown) => (
            <Input
              {...inputProps}
              type="text"
              placeholder="000.000.000-00"
              variant="custom"
              size="md"
              data-testid="cpf-input"
            />
          )}
        </InputMask>
      </FormControl>
      <FormControl data-testid="query-modal-form-name">
        <FormLabel mb="8px">Renda mensal</FormLabel>
        <Input
          variant="custom"
          size="md"
          data-testid="name-input"
          placeholder="Sua renda mensal..."
          onChange={(e) => setSallary(e.target.value)}
          value={sallary}
        />
      </FormControl>

      <FormControl id="cep">
        <FormLabel mb="8px">CEP</FormLabel>
        <InputMask
          mask="99999-999"
          onChange={(e) => setCep(e.target.value)}
          value={cep}
        >
          {(inputProps: unknown) => (
            <Input
              {...inputProps}
              type="text"
              placeholder="00000-000"
              variant="custom"
              size="lg"
              data-testid="cep-input"
            />
          )}
        </InputMask>
      </FormControl>

      <Checkbox
        onChange={(e) => setAgreed(e.target.checked)}
      >
        Li e concordo com os termos de uso do Scorei.

      </Checkbox>

      <Button
        size="lg"
        colorScheme="brand"
        onClick={async () => {
          if (!agreed) {
            toast({
              status: 'info',
              title: 'Você precisa preencher os dados e concordar com os termos.'
            });

            return;
          }

          await submit(name, email, cpf, cep, sallary);

          setName('');
          setEmail('');
          setCpf('');
          setCep('');
          setSallary('');
        }}
        isLoading={loading}
      >
        Consultar
      </Button>
    </Stack>
  );
};

const ScoreiGauge: React.FC = function () {
  const [loading, setLoading] = useState(false);
  const [score, setScore] = useState(0);
  const [offers, setOffers] = useState<IOffer[]>([]);
  const [nameGauge, setName] = useState<string>('');

  const toast = useToast();

  const submit = async (
    name: string,
    email: string,
    cpf: string,
    cep: string,
    sallary: string
  ): Promise<boolean> => {
    if (!name || !email || !cpf || !cep || !sallary) {
      toast({
        title: 'Informe todos os dados necessários',
        description: 'Confira se preencheu todos os dados',
        status: 'warning',
        duration: 9000,
        isClosable: true
      });

      return false;
    }

    setLoading(true);

    const { data } = await scoreiApi.post('/request/person/score', {
      name,
      email,
      cpf,
      cep,
      sallary: parseFloat(sallary)
    });

    setScore(data.score.value * 10);
    setOffers(data.offers);
    setName(name);

    setLoading(false);

    return true;
  };

  return (
    <Container
      maxW="container.lg"
      pt={{ base: '80px', lg: '120px' }}
      pb="80px"
      id="consultar"
    >
      <Heading textAlign="center" mb={{ base: 0, lg: '48px' }}>
        Consulte seu CPF online
      </Heading>
      <Grid
        templateColumns={{ base: '1fr', lg: score ? '500px' : '500px 500px' }}
        justifyContent="center"
        gap={{ base: 0, lg: '32px' }}
      >
        <Box mb={{ base: '32px', lg: 0 }}>
          <Score score={score || 970} name={nameGauge || 'João Silva'} home />
          {
            !!offers.length
            && <CreditOffers offers={offers} />
          }
        </Box>
        <Box d={score ? 'none' : 'block'}>
          <PFForm
            submit={(
              name: string,
              email: string,
              cpf: string,
              cep: string,
              sallary: string
            ) => submit(name, email, cpf, cep, sallary)}
            loading={loading}
          />
        </Box>
      </Grid>
    </Container>
  );
};

export default ScoreiGauge;
