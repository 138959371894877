import { extendTheme } from '@chakra-ui/react';
import colors from './colors';
import fonts from './fonts';
import Button from './button';
import Link from './Link';
import Input from './input';
import FormLabel from './FormLabel';

const theme = extendTheme({
  colors,
  fonts,
  components: {
    Button, Link, Input, FormLabel
  }
});

export default theme;
