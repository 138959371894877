import React, { useEffect, useState } from 'react';

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Card from 'Components/elements/Card';
import api from 'Core/services/api';
import IHistoryItem from 'Core/types/IHistoryItem';
import moment from 'moment';
import 'moment/locale/pt-br';

const HistoryBox: React.FC = function () {
  const navigate = useNavigate();

  const [items, setItems] = useState<IHistoryItem[]>([]);

  const toast = useToast();

  useEffect(() => {
    async function getData(): Promise<void> {
      try {
        const { data } = await api.get('/request/history-sicoob');

        setItems(data);
      } catch (e) {
        toast({
          title: 'Ops',
          description: 'Algo de errado aconteceu.',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      }
    }
    getData();
  }, [toast]);

  return (
    <Card title="Histórico" testId="history-box">
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th>Nome</Th>
            <Th>CPF</Th>
            <Th>CEP</Th>
            <Th>Score</Th>
            <Th>Data</Th>
          </Tr>
        </Thead>
        <Tbody>
          {
            items.map((item: any, i) => (
              <Tr
                cursor="pointer"
                _hover={{
                  bgColor: 'gray.50'
                }}
                onClick={() => navigate(`/dashboard/query/${i}`)}
                key={item.id}
              >
                <Td>{item.input.name || item.input.nome}</Td>
                <Td>{item.input.cpf || item.input.cnpj}</Td>
                <Td>{item.input.cep}</Td>
                <Td>{item.output.score * 10}</Td>
                <Td>{moment(item.date).locale('pt-br').calendar()}</Td>
              </Tr>
            ))
          }
        </Tbody>
      </Table>
    </Card>
  );
};

export default HistoryBox;
