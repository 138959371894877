/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import {
  Box,
  Grid,
  HStack,
  Stack,
  Text,
  Button,
  Link,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter
} from '@chakra-ui/react';
import DashboardTemplate from 'Components/templates/DashboardTemplate';
import SettingsSidebar from 'Components/layout/dashboard/SettingsSidebar';
import Card from 'Components/elements/Card';
import api from 'Core/services/api';
import moment from 'moment';
import CreditCard from 'Components/elements/CreditCard';
import InputMask from 'react-input-mask';
import IPlan from 'Core/types/IPlans';
import Plan from 'Components/elements/PlanUpdate';
import Business from 'Assets/Business.svg';

interface IStatus {
  expires_date: string,
  pay_date: string,
  status: string,
  subscription?: any,
  used: number
}

interface ICard {
  data: any,
}

const Settings: React.FC = function () {
  const [status, setStatus] = useState<IStatus>();
  const [cards, setCards] = useState<ICard[]>();
  const [cardNumber, setcardNumber] = useState<string>();
  const [expiresIn, setexpiresIn] = useState<string>();
  const [cvc, setcvc] = useState<string>();
  const [cardName, setcardName] = useState<string>();
  const [plans, setPlans] = useState<IPlan[]>([]);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  async function getData(): Promise<void> {
    try {
      const { data } = await api.get('/payment/status');

      setStatus(data);
    } catch (e) {
      toast({
        title: 'Ops',
        description: 'Algo de errado aconteceu.',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
  }

  async function getCards(): Promise<void> {
    try {
      const datacards = await api.get('/payment/cards');

      setCards(datacards.data);
    } catch (e) {
      toast({
        title: 'Ops',
        description: 'Algo de errado aconteceu.',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
  }

  async function getPlans(): Promise<void> {
    try {
      const { data } = await api.get('/plans/all');
      setPlans(data);
    } catch (e) {
      toast({
        title: 'Ops',
        description: 'Algo de errado aconteceu.',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
  }

  useEffect(() => {
    getData();
    getCards();
    getPlans();
  }, [toast]);

  async function cancel(): Promise<void> {
    try {
      const { data } = await api.post('/payment/cancel', {
        subscriptionId: status?.subscription.subscriptionId
      });
      console.log(data);
      toast({
        title: 'Sucesso!',
        description: 'Seu plano foi cancelado.',
        status: 'success',
        duration: 9000,
        isClosable: true
      });
    } catch (e) {
      toast({
        title: 'Ops',
        description: 'Algo de errado aconteceu.',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
  }

  const updatePayment = async (card: string): Promise<void> => {
    try {
      const { data } = await api.patch('/payment/payment', {
        subscriptionId: status?.subscription.subscriptionId,
        paymentMethod: card
      });
      console.log(data);

      getData();

      toast({
        title: 'Sucesso!',
        description: 'Seu metodo de pagamento foi atualizado.',
        status: 'success',
        duration: 9000,
        isClosable: true
      });
    } catch (e) {
      toast({
        title: 'Ops',
        description: 'Algo de errado aconteceu.',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
  };

  const deletePayment = async (card: string): Promise<void> => {
    try {
      const { data } = await api.delete(`/payment/payment/${card}`);

      console.log(data);

      getCards();

      toast({
        title: 'Sucesso!',
        description: 'Seu metodo de pagamento foi deletado.',
        status: 'success',
        duration: 9000,
        isClosable: true
      });
    } catch (e) {
      toast({
        title: 'Ops',
        description: 'Algo de errado aconteceu.',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
  };

  const updatePlan = async (planId: string): Promise<void> => {
    try {
      const { data } = await api.patch('/payment/plan', {
        subscriptionId: status?.subscription.subscriptionId,
        planId
      });
      console.log(data);

      getData();
      getPlans();
      toast({
        title: 'Sucesso!',
        description: 'Seu Plano foi atualizado.',
        status: 'success',
        duration: 9000,
        isClosable: true
      });
    } catch (e) {
      toast({
        title: 'Ops',
        description: 'Algo de errado aconteceu.',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
  };

  async function createCard(): Promise<void> {
    try {
      const data = await api.post('/payment/cards', {
        cardNumber,
        expiresIn,
        cvc,
        cardName
      });
      toast({
        title: 'Sucesso!',
        description: 'Seu cartão foi criado.',
        status: 'success',
        duration: 9000,
        isClosable: true
      });

      getCards();
      onClose();
    } catch (e: any) {
      toast({
        title: 'Ops',
        description: e.error.raw.message,
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
  }

  return (
    <DashboardTemplate>
      <Grid templateColumns={{ base: '1fr', lg: '200px auto' }} gap="24px">
        <Box>
          <SettingsSidebar />
        </Box>
        <Stack spacing="24px">
          <Card title="Pagamentos">
            <Grid templateColumns={{ base: '1fr', lg: '1fr 1fr' }} gap="24px">
              <Box>
                <Box
                  p="32px"
                  bgColor="brand.500"
                  color="white"
                  mb="12px"
                >
                  <Text fontWeight={500}>Plano atual</Text>
                  <Text fontSize="32px" fontWeight="600">{!status?.subscription ? 'Trial Plan' : status.subscription.name}</Text>
                  <Text fontWeight={500}>
                    Válido até
                    {' '}
                    {moment(status?.expires_date).format('DD/MM/YYYY')}
                  </Text>
                </Box>
              </Box>
              <Box>
                {/* <Text fontSize={18} fontWeight={600} mb="8px">Cartão</Text>
                <HStack>
                  <Text>**** **** **** 4498</Text>
                  <Button size="xs" colorScheme="brand" variant="ghost">Alterar</Button>
                </HStack> */}
                <Text>
                  Pagamento feito em
                  {' '}
                  {moment(status?.pay_date).format('DD/MM/YYYY')}
                </Text>
                <Link href={status?.subscription ? '#planos' : '/#planos'}>
                  <Button
                    mt="24px"
                    size="md"
                    w="100%"
                    colorScheme="brand"
                  >
                    Upgrade
                  </Button>
                </Link>
              </Box>
            </Grid>
          </Card>

          <Card title="Cartões">
            {
              status?.subscription && (
                <Button
                  onClick={onOpen}
                  colorScheme="brand"
                  float="right"
                  marginTop="-50px"
                >
                  Adicionar Cartão

                </Button>
              )
            }
            {
              status?.subscription && (
                <Grid templateColumns={{ base: '1fr', lg: '1fr 1fr' }} gap="24px">
                  {
                    cards?.map((card: any) => (
                      <CreditCard
                        data={card}
                        defaultCard={card.id === status?.subscription.paymentMethod}
                        functionCard={updatePayment}
                        functionDelete={deletePayment}
                      />
                    ))
                  }
                </Grid>
              )
            }
          </Card>
          {
            status?.subscription
            && (
              <Card title="Planos disponíveis">
                <Grid id="planos" templateColumns={{ base: '1fr', lg: '1fr 1fr 1fr' }} gap="24px">
                  {/* eslint no-underscore-dangle: 0 */
                    plans.map((plan: any) => (
                      (plan.credits > status?.used || plan._id === status?.subscription.planId) && (
                        <Plan
                          id={plan._id}
                          icon={Business}
                          title={plan.name}
                          subtitle=""
                          key={plan._id}
                          price={plan.price}
                          defaultPlan={plan._id === status?.subscription.planId}
                          subprice="Cobrado mensalmente"
                          features={[
                            `${plan.credits} consultas de score PF e PJ`
                          ]}
                          isPopular={plan.isPopular || false}
                          functionPlan={updatePlan}
                        />
                      )
                    ))
                  }
                </Grid>
              </Card>
            )
          }
          {
            status?.subscription && (
              <Button
                onClick={() => cancel()}
                color="red.500"
                width="200px"
                float="right"
                fontSize="10px"
                right="0"

              >
                Cancelar assinatura

              </Button>
            )
          }
        </Stack>

      </Grid>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Adicionar Cartão</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Nome no cartão</FormLabel>
              <Input
                variant="custom"
                id="form-checkout__cardholderName"
                onChange={(e) => setcardName(e.target.value)}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Numero do Cartão</FormLabel>
              <InputMask
                mask="9999 9999 9999 9999"
                id="form-checkout__cardNumber"
                onChange={(e) => setcardNumber(e.target.value)}
              >
                {(inputProps: unknown) => (
                  <Input
                    {...inputProps}
                    variant="custom"
                  />
                )}
              </InputMask>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Data de Expiração</FormLabel>
              <InputMask
                mask="99/99"
                id="form-checkout__securityCode"
                onChange={(e) => setexpiresIn(e.target.value)}
              >
                {(inputProps: unknown) => (
                  <Input
                    {...inputProps}
                    variant="custom"
                  />
                )}
              </InputMask>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>CVV</FormLabel>
              <InputMask
                mask="999"
                id="form-checkout__securityCode"
                onChange={(e) => setcvc(e.target.value)}
              >
                {(inputProps: unknown) => (
                  <Input
                    {...inputProps}
                    variant="custom"
                  />
                )}
              </InputMask>
            </FormControl>
          </ModalBody>

          <ModalFooter>

            <Button onClick={onClose}>Cancelar</Button>
            <Button colorScheme="blue" mr={3} onClick={() => createCard()}>
              Criar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </DashboardTemplate>

  );
};

export default Settings;
