import React from 'react';
import {
  Container, Heading, Box, Text,
  Button,
  Link
} from '@chakra-ui/react';
import { SiWhatsapp } from 'react-icons/si';

const Contact: React.FC = function () {
  return (
    <Box id="contato">
      <Container maxW="container.xl" py="80px">
        <Box textAlign="center" mb="32px">
          <Heading
            as="h3"
            textTransform="uppercase"
            color="brand.500"
            fontFamily="DM Mono"
            fontSize={{ base: '18px', lg: '24px' }}
            fontWeight="500"
            mb="8px"
          >
            Fale conosco
          </Heading>
          <Heading
            as="h2"
            color="brand.900"
            fontWeight="700"
            fontSize={{ base: '28px', lg: '40px' }}
            mx="auto"
            mb="24px"
          >
            Precisa de suporte especializado?
          </Heading>
          <Text fontSize={{ base: '18px', lg: '24px' }} fontWeight="500" color="brand.900">
            Entre em contato com nossa equipe para consultas personalizadas ou
            resolução de dúvidas e problemas
          </Text>

          <Link href="https://api.whatsapp.com/send?phone=5511995736666">
            <Button
              bgColor="#25d366"
              color="white"
              _hover={{ bgColor: '#14b851' }}
              size="lg"
              mt="32px"
              leftIcon={<SiWhatsapp size="24px" />}
            >
              Chamar no whatsapp
            </Button>
          </Link>
        </Box>

        {/* <Box py="54px">
          <Grid templateColumns={{ base: '1fr', lg: '1fr 1fr' }} gap="24px" mb="32px">
            <Stack spacing="24px">
              <FormControl id="nome">
                <FormLabel mb="8px">Seu nome</FormLabel>
                <Input type="text" placeholder="Seu nome..." variant="custom" size="lg" />
              </FormControl>
              <FormControl id="email">
                <FormLabel mb="8px">Seu email</FormLabel>
                <Input type="email" placeholder="Seu email..." variant="custom" size="lg" />
              </FormControl>
              <FormControl id="telefone">
                <FormLabel mb="8px">Seu telefone</FormLabel>
                <Input type="text" placeholder="Seu telefone..." variant="custom" size="lg" />
              </FormControl>
              <FormControl id="companyName">
                <FormLabel mb="8px">Nome da sua empresa</FormLabel>
                <Input
                type="text" placeholder="Nome da sua empresa..." variant="custom" size="lg" />
              </FormControl>
            </Stack>

            <FormControl id="email">
              <FormLabel mb="8px">Sua mensagem</FormLabel>
              <Textarea
                placeholder="Sua mensagem..."
                h="calc(100% - 32px)"
                borderRadius="0"
                fontFamily="DM Mono"
                size="lg"
              />
            </FormControl>
          </Grid>

          <Button colorScheme="brand">Enviar</Button>
        </Box> */}
      </Container>
    </Box>
  );
};

export default Contact;
