/* eslint-disable no-console */
import React, {
  createContext, useState, useMemo, useCallback, useEffect
} from 'react';
import IAuthContext, { DEFAULT_VALUES } from 'Core/types/IAuthContext';
import IUser from 'Core/types/IUser';
import api from 'Core/services/api';

export const AuthContext = createContext<IAuthContext>(DEFAULT_VALUES);

const AuthProvider: React.FC = function ({ children }) {
  const [user, setUser] = useState<IUser>(DEFAULT_VALUES.user);
  const [token, setToken] = useState<string>(DEFAULT_VALUES.token);

  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    const savedUser = localStorage.getItem('user');

    if (savedToken) setToken(savedToken);
    if (savedUser) setUser(JSON.parse(savedUser));
  }, []);

  useEffect(() => {
    if (token) api.defaults.headers.common = { Authorization: `Bearer ${token}` };
  }, [token]);

  const authenticate = useCallback((_user: IUser, _token: string): void => {
    setUser(_user);
    setToken(_token);

    localStorage.setItem('user', JSON.stringify(_user));
    localStorage.setItem('token', _token);

    // window.location.href = '/pessoa-fisica';
  }, []);

  const logoff = useCallback((): void => {
    setUser(DEFAULT_VALUES.user);
    setToken(DEFAULT_VALUES.token);

    localStorage.removeItem('user');
    localStorage.removeItem('token');

    window.location.href = '/login';
  }, []);

  const updateUser = useCallback((_user: IUser): void => {
    setUser(_user);
    localStorage.setItem('user', JSON.stringify(_user));
  }, []);

  const isAuthenticated = useCallback((): boolean => !!localStorage.getItem('token'), []);

  api.interceptors.response.use((response) => response, (error) => {
    if (error.response.status === 401) {
      logoff();
    }
    return error;
  });

  const memoizedValues = useMemo(
    () => ({
      token,
      user,
      authenticate,
      isAuthenticated,
      logoff,
      updateUser
    }),
    [
      token,
      user,
      authenticate,
      isAuthenticated,
      logoff,
      updateUser
    ]
  );

  return (
    <AuthContext.Provider
      value={memoizedValues}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
