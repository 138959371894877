/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import {
  Box, Container, Button,
  Image, FormControl, FormLabel,
  Grid, Heading, HStack, Input, Stack, Text, Link, Select, toast
} from '@chakra-ui/react';
import Navbar from 'Components/layout/Navbar';
import Footer from 'Components/layout/Footer';
import Card from 'Components/elements/Card';
import Business from 'Assets/Business.svg';
import InputMask from 'react-input-mask';
import { useMercadopago } from 'react-sdk-mercadopago';
import api from 'Core/services/api';
import IPlan from 'Core/types/IPlans';
import { useNavigate } from 'react-router-dom';

const CheckoutComponent: React.FC<{ plan: IPlan }> = function ({ plan }) {
  const navigate = useNavigate();

  const [cardNumber, setcardNumber] = useState<string>();
  const [expiresIn, setexpiresIn] = useState<string>();
  const [cvc, setcvc] = useState<string>();
  const [cardName, setcardName] = useState<string>();
  const [cupom, setCupom] = useState<string>();

  async function getData(): Promise<void> {
    try {
      const { data } = await api.get('/payment/status');
      if (data.subscription) {
        navigate('/configuracoes/pagamento#planos');
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getData();
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onSubmit(): void {
    api.post('/payment/process_payment', {
      cardNumber,
      expiresIn,
      cvc,
      cardName,
      cupom,
      plan: plan._id
    }).then((res) => {
      if (res.data.statusCode === 402) {
        alert(res.data.raw.message);
        navigate(`/checkout?plan=${plan._id}`);
      }
      if (res.data.status === 'active') { navigate('/checkout/success'); }
      if (res.data.status === 'incomplete ') { alert('Seu Pagamento esta em Analise, se não receber algum retorno, tente novamente em 24 horas.'); navigate('/'); }
    }).catch((err) => {
      alert(err.error);
      navigate(`/checkout?plan=${plan._id}`);
    });

    navigate('/checkout/message');
  }
  return (
    <Box>
      <Navbar />
      <Box
        bgColor="brand.500"
        pt="128px"
        pb="96px"
        color="white"
      >
        <Container maxW="container.xl">
          <Heading fontSize={{ base: '32px', lg: '55px' }}>Assinatura Dataholics</Heading>
          <Text>Insira seus dados de pagamento para finalizar o processo de assinatura</Text>
        </Container>
      </Box>

      <form id="form-checkout" onSubmit={onSubmit}>
        <Container maxW="container.lg" py="80px">
          <Grid
            gap="24px"
            templateColumns={{ base: '1fr', lg: '1fr 0.7fr' }}
          >
            <Stack spacing="24px">
              <Card title="Dados do pagador">
                <Stack spacing="16px">
                  <FormControl>
                    <FormLabel>Nome do Cartão</FormLabel>
                    <Input
                      variant="custom"
                      id="form-checkout__cardholderName"
                      onChange={(e) => setcardName(e.target.value)}
                    />
                  </FormControl>
                </Stack>
              </Card>

              <Card title="Dados pagamento">
                <Stack spacing="16px">
                  <FormControl>
                    <FormLabel>Cartão</FormLabel>
                    <InputMask
                      mask="9999 9999 9999 9999"
                      id="form-checkout__cardNumber"
                      onChange={(e) => setcardNumber(e.target.value)}
                    >
                      {(inputProps: unknown) => (
                        <Input
                          {...inputProps}
                          variant="custom"
                        />
                      )}
                    </InputMask>
                  </FormControl>
                  <Grid gap="24px" templateColumns="1fr 1fr">
                    <FormControl>
                      <FormLabel>Validade</FormLabel>
                      <InputMask
                        mask="99/99"
                        id="form-checkout__securityCode"
                        onChange={(e) => setexpiresIn(e.target.value)}
                      >
                        {(inputProps: unknown) => (
                          <Input
                            {...inputProps}
                            variant="custom"
                          />
                        )}
                      </InputMask>
                    </FormControl>
                    <FormControl>
                      <FormLabel>CVC</FormLabel>
                      <InputMask
                        mask="999"
                        id="form-checkout__securityCode"
                        onChange={(e) => setcvc(e.target.value)}
                      >
                        {(inputProps: unknown) => (
                          <Input
                            {...inputProps}
                            variant="custom"
                          />
                        )}
                      </InputMask>
                    </FormControl>
                    <FormControl d="none">
                      <FormLabel>Issuer</FormLabel>
                      <Select id="form-checkout__issuer" />
                    </FormControl>
                  </Grid>

                </Stack>
              </Card>

              <Card title="Cupom de Desconto">
                <Stack spacing="16px">
                  <FormControl>
                    <FormLabel>Nome do cupom</FormLabel>
                    <Input
                      variant="custom"
                      id="form-checkout__cardholderName"
                      onChange={(e) => setCupom(e.target.value)}
                    />
                  </FormControl>
                </Stack>
              </Card>

              <Button
                size="lg"
                colorScheme="brand"
                w="100%"
                mt="32px"
                mb="0"
                type="submit"
                id="form-checkout__submit"
              >
                Confirmar pagamento
              </Button>
            </Stack>
            <Box>
              <Card title="Assinatura">
                <Image src={Business} />
                <Text fontWeight="700" mt="8px">
                  Plano
                  {' '}
                  {plan?.name}
                </Text>
                <Text mt="16px" color="gray.500">Total</Text>
                <HStack>
                  <Text
                    fontSize="32px"
                    fontWeight="800"
                    color="brand.500"
                    lineHeight="1em"
                  >
                    R$
                    {plan?.price.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                  </Text>
                  <Text>/mês</Text>
                </HStack>

                <Link
                  href="/"
                  textAlign="center"
                  mt="24px"
                  d="block"
                >
                  Alternar plano
                </Link>
              </Card>
            </Box>
          </Grid>
        </Container>
      </form>
      <Footer />
    </Box>
  );
};

export default CheckoutComponent;
